.sticky-footer {
    align-items: center;
    background-color: #0f1b2a;
    bottom: 0;
    display: flex;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    justify-content: space-between;
    margin: 0;
    min-block-size: 30px;
    padding-block: 5px;
    padding-inline: 20px;
    position: fixed;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 2000;
  }
  
  .footer-text {
    margin: 0;
    color: white;
    flex: 1;
    text-align: center;
  }
  
  .sticky-footer .footer-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 10px;
    margin: 0;
    flex-wrap: wrap;
  }
  
  .sticky-footer li,
  .sticky-footer a {
    color: white;
    text-decoration: none;
  }
  
  .sticky-footer a {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .sticky-footer .external-icon {
    display: inline-block;
    fill: none;
    block-size: 16px;
    stroke-width: 2px;
    stroke: currentColor;
    inline-size: 16px;
    position: relative;
    top: -2px;
  }
  
  .sticky-footer .stroke-linecap-square {
    stroke-linecap: square;
  }
  
  .sticky-footer .stroke-linejoin-round {
    stroke-linejoin: round;
  }